<template>
  <div class="page_bg">
    <!-- 顶部搜索区域 -->
    <!-- <van-sticky> -->
    <div class="search_top  flex-c-s-c" style="position: fixed;top:0;z-index:999">
      <div class="ser_search  flex-r-sb-c">
        <div />
        <van-icon name="arrow-left" size="0.5rem" @click="backPath" />
        <van-search ref="searchVal" v-model="searchValue" class="classify_ser" placeholder="请输入搜索内容" shape="round"
                    @search="inputSer" @focus="onFocus" @input="inputWord"
        >
          <template #right-icon>
            <van-button round color="#0767AB" size="small" style="width:1.49rem;height:0.69rem;margin-top:-0.1rem"
                        @click="inputSer"
            >搜索</van-button>
          </template>
        </van-search>
        <div />
      </div>
    </div>
    <!-- 展示搜索页面 -->
    <div v-if="showMore" class="show_ser_more" style="margin-top:1.6rem">
      <div v-for="(more,more_index) in wordList" :key="more_index" class="word_item flex-c-c-c">
        <div class="word_box flex-r-s-s" @click="inputSerWord(more)">
          <van-icon name="search" size="0.5rem" />
          <div class="word van-ellipsis"
               v-html="parseInt(more.type) === 2 ? more.proName : more.searchTagNameHighLight"
          />
        </div>
        <div class="diver_word" />
      </div>
    </div>
    <div v-else>
      <!-- 历史查询 -->
      <div v-if="historyList.length>0" class="search_history" style="margin-top:1.8rem">
        <div class="search_history_top flex-r-sb-c">
          <div class="history_top_name">搜索历史</div>
          <div class="history_top_icon" @click="clearHistory">
            <van-icon name="delete-o" />
          </div>
        </div>
        <div class="history_contant flex-r-s-s">
          <div v-for="(his,his_index) in historyList" :key="his_index" @click="HSearch(his)">
            <div class="history_message van-ellipsis">{{ his }}</div>
          </div>
        </div>
      </div>
      <!-- 热门搜索 -->
      <div class="search_hot">
        <div class="search_hot_title">热门搜索</div>
        <van-row>
          <div class="search_hot_contant">
            <van-col v-for="(hot,hot_index) in hotList" :key="hot_index" :span="12">
              <div class="hot_item flex-r-s-c van-ellipsis" @click="HSearch(hot.search)">
                <div v-if="hot_index === 0" class="hot_index hot_index_1">{{ hot_index+1 }}</div>
                <div v-if="hot_index === 1" class="hot_index hot_index_2">{{ hot_index+1 }}</div>
                <div v-if="hot_index === 2" class="hot_index hot_index_3">{{ hot_index+1 }}</div>
                <div v-if="hot_index > 2" class="hot_index">{{ hot_index+1 }}</div>
                <div class="hot_msg">{{ hot.search }}</div>
              </div>
            </van-col>
          </div>
        </van-row>
      </div>
    </div>
    <!-- loading遮罩层 -->
    <van-overlay :show="overlayShow" @click="overlayShow = false">
      <div class="overlay_loading">
        <van-loading color="#eee" size="0.6rem" type="spinner" vertical>数据正在赶来的路上...</van-loading>
      </div>
    </van-overlay>

  </div>
</template>

<script>
import './index.scss'
import { mallProduct_hotSearch, mallProduct_searchTagList } from '@/api/pro'
export default {
  data() {
    return {
      isSer: false,
      searchForm: { pageNum: 1, pageSize: 10, proType: 3 },
      tabIndex: 1,
      searchValue: '',
      historyList: JSON.parse(localStorage.getItem('historySer')) || [],
      hotList: [],
      proList: [],
      listLoading: false,
      overlayShow: false,

      showMore: false,
      wordList: []
    }
  },
  mounted() {
    this.$nextTick(() => {
      // 使用$nextTick或setTimeout处理触发弹框自动获取焦点延迟的问题
      this.$refs.searchVal.querySelector('input').focus()
    })
  },
  created() {
    if (this.$route.query.name) {
      this.searchValue = this.$route.query.name
      this.setHistory(this.$route.query.name)
      this.$router.push('/product/searchproList?name=' + this.$route.query.name)
    }
    this.loadHot()
  },
  methods: {
    backPath() { window.history.back() },
    changeType(type) {
      this.tabIndex = type
    },
    // 清空搜索历史
    clearHistory() {
      this.$dialog.confirm({ message: '是否清空历史查询记录' })
        .then(() => {
          localStorage.removeItem('historySer')
          this.historyList = []
        })
        .catch(() => { })
    },
    // 加载热门搜索
    loadHot() { mallProduct_hotSearch().then(res => { this.hotList = res.data.list }) },
    // 热门、历史查询
    HSearch(val) {
      this.setHistory(val)
      this.$router.push('/product/searchproList?name=' + val)
      // if (val === '贝思倍健') {
      //   this.$router.push('/product/subjectPage_bsbj')
      // } else {
      //   this.$router.push('/product/searchproList?name=' + val)
      // }
    },
    // 存localstrong 事件
    setHistory(e) {
      for (var i = 0; i < this.historyList.length; i++) {
        if (this.historyList[i] === e) { this.historyList.splice(i, 1) }
      }
      this.historyList.unshift(e)
      localStorage.setItem('historySer', JSON.stringify(this.historyList))
    },
    // 获焦事件
    onFocus() {
      // this.$refs.myList01.load_status_clear()
      this.isSer = false
      this.searchValue = ''
      this.proList = []
      this.searchForm.pageNum = 1
    },
    inputSer() {
      this.setHistory(this.searchValue)
      this.$router.push('/product/searchproList?name=' + this.searchValue)
    },
    inputSerWord(item) {
      const val = parseInt(item.type) === 2 ? item.proName : item.searchTagName
      this.setHistory(val)
      this.$router.push('/product/searchproList?val=' + val + '&type=' + item.type + '&searchTagId=' + item.searchTagId)
      // if (item.proName === '贝思倍健' || item.searchTagName === '贝思倍健') {
      //   this.$router.push('/product/subjectPage_bsbj')
      // } else {
      //   this.$router.push('/product/searchproList?val=' + val + '&type=' + item.type + '&searchTagId=' + item.searchTagId)
      // }
    },
    inputWord(e) {
      console.log(e)
      if (e.length >= 1) {
        mallProduct_searchTagList({ pageNum: 1, pageSize: 999, keyWords: e }).then(res => {
          this.wordList = res.data.data
        })
        this.showMore = true
      } else {
        this.wordList = []
        this.showMore = false
      }
    }
  }
}
</script>

<style>
</style>
